<template>
  <div class="page">
    <!--logs.wxml-->
    <div class="container">
      <img class="full-img" mode="widthFix" src="https://tugo-1257547782.cos.ap-beijing.myqcloud.com/uploads/weapp_res/universal/uni_01.jpg">
      <div class="product_box">
        <div class="tickets_box">
          <div class="tickets">
            <div class="t_img_box">
              <router-link :to="ticket_url" hover-class="navigator-hover">
                <img src="https://tugo-1257547782.cos.ap-beijing.myqcloud.com/uploads/weapp_res/universal/shangpin.jpg">
              </router-link>
            </div>
          </div>
        </div>
        <div class="hotel_box">
          <div class="hotel_one">
            <img src="https://tugo-1257547782.cos.ap-beijing.myqcloud.com/uploads/weapp_res/universal/huanqing_hotel.jpg">
          </div>
          <div class="hotel_two">
            <img src="https://tugo-1257547782.cos.ap-beijing.myqcloud.com/uploads/weapp_res/universal/huojin_hotel.jpg">
          </div>
        </div>
      </div>
	  <div class="lp_product_box">
		  <router-link :to="uni_lp_url" hover-class="navigator-hover">
		  <img class="full-img" mode="widthFix" src="https://tugo-1257547782.cos.ap-beijing.myqcloud.com/uploads/weapp_res/universal/uni_lp.jpg">
		  </router-link>
	  </div>
      <img class="full-img" mode="widthFix" src="https://tugo-1257547782.cos.ap-beijing.myqcloud.com/uploads/weapp_res/universal/uni_03.jpg">
      <img class="full-img" mode="widthFix" src="https://tugo-1257547782.cos.ap-beijing.myqcloud.com/uploads/weapp_res/universal/uni_04.jpg">
      <img class="full-img" mode="widthFix" src="https://tugo-1257547782.cos.ap-beijing.myqcloud.com/uploads/weapp_res/universal/uni_05.jpg">
      <img class="full-img" mode="widthFix" src="https://tugo-1257547782.cos.ap-beijing.myqcloud.com/uploads/weapp_res/universal/uni_06.jpg">
      <img class="full-img" mode="widthFix" src="https://tugo-1257547782.cos.ap-beijing.myqcloud.com/uploads/weapp_res/universal/uni_07.jpg">
      <img class="full-img" mode="widthFix" src="https://tugo-1257547782.cos.ap-beijing.myqcloud.com/uploads/weapp_res/universal/uni_08.jpg">
      <img class="full-img" mode="widthFix" src="https://tugo-1257547782.cos.ap-beijing.myqcloud.com/uploads/weapp_res/universal/uni_09.jpg">
      <img class="full-img" mode="widthFix" src="https://tugo-1257547782.cos.ap-beijing.myqcloud.com/uploads/weapp_res/universal/uni_10.jpg">
      <img class="full-img" mode="widthFix" src="https://tugo-1257547782.cos.ap-beijing.myqcloud.com/uploads/weapp_res/universal/uni_11.jpg">
      <img class="full-img" mode="widthFix" src="https://tugo-1257547782.cos.ap-beijing.myqcloud.com/uploads/weapp_res/universal/uni_12.jpg">
    </div>
  </div>
</template>

<script>
  export default {
    name: 'universal',
    data() {
      return {
        ticket_url:'#',
        un_hotel: '#',
        gold_hotel:'#',
		uni_lp_url:'#'
      }
    },
    components: {
    },
    created() {
		this.fetchData()
    },
    methods: {
      fetchData() {
        let that = this;
        this.post(
          this.API.tourGetSubjectUrl,{}
        ).then(response => {
			
			console.log(response.data.url.data);
          let pageUrl = response.data.url.data.data;
          let ticket_url = '';
		  let uni_lp_url = '';
          let un_hotel = '';
          let gold_hotel = '';
          pageUrl.forEach(function(i,v){
            if(i.v_url_type=='tickets')
            {
              ticket_url = i.v_subject_url;
            }
			if(i.v_url_type=='uni_lp')
			{
			  uni_lp_url = i.v_subject_url;
			}
            if(i.v_url_type=='un_hotel')
            {
              un_hotel = i.v_subject_url;
            }
            if(i.v_url_type=='gold_hotel')
            {
              gold_hotel = i.v_subject_url;
            }
          })

          that.ticket_url=ticket_url
          that.un_hotel=un_hotel
          that.gold_hotel=gold_hotel
		  that.uni_lp_url = uni_lp_url
        })
      },
    }
  };
</script>

<style lang="scss" scoped>
  .page {
    min-height: 100%;
    background-color: #F5F5F5;
  }

  .product_box {
    height: 680px;
    overflow: hidden;
    background: url(https://tugo-1257547782.cos.ap-beijing.myqcloud.com/uploads/weapp_res/universal/uni_02.jpg)  no-repeat;
    background-size: cover;
  }
  
  .lp_product_box {
    height: 760px;
    overflow: hidden;
    background-size: cover;
  }

  .tickets_box {
    padding:32px;
    padding-top: 142px;
  }

  .tickets_box .tickets {
    height: 510px;
    border-radius: 10px;
    background: #FFFFFF;
  }
  .tickets_box .tickets .t_img_box {
    padding:20px;
  }
  .tickets_box .tickets .t_img_box img {
    width:650px;
    height: 463px;
  }

  .hotel_box {
    padding:32px;
    padding-top: 0px;
    display: flex;
  }
  .hotel_one {
    height: 320px;
    display: flex;
    flex: 1;
    border-radius: 10px;
    background: #FFFFFF;
  }

  .hotel_two {
    height: 320px;
    display: flex;
    flex: 1;
    border-radius: 10px;
    background: #FFFFFF;
    margin-left: 30px;
  }

  .hotel_box img {
    width: 295px;
    height: 281px;
    margin:20px;
  }

</style>
